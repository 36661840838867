export const ENV_PROD = "production";
export const ENV_DEV = "development";
export const HTTP_LOCAL_PORT = "8111";
export const HTTP_LOCAL_HOST = "http://127.0.0.1";

export const ENV_LIST = [ENV_DEV, ENV_PROD];

export const SPECS_API_TYPE_MAIN = "main-api";
export const SPECS_API_TYPE_LEVEL_ONE = "level-one-api";
export const SPECS_API_TYPE_TERMINAL_SERVICE = "terminal-api";
export const SPECS_API_TYPE_ANDROID_SDK = "android-sdk";
export const SPECS_API_TYPE_IOS_SDK = "ios-sdk";
export const SPECS_API_TYPE_WEB_SDK = "web-sdk";
export const SPECS_API_TYPE_PAX_SDK = "pax-sdk";

export const SPECS_API_TYPES = [
  SPECS_API_TYPE_MAIN,
  SPECS_API_TYPE_LEVEL_ONE,
  SPECS_API_TYPE_TERMINAL_SERVICE,
  SPECS_API_TYPE_ANDROID_SDK,
  SPECS_API_TYPE_IOS_SDK,
  SPECS_API_TYPE_WEB_SDK,
  SPECS_API_TYPE_PAX_SDK,
];

export const SPECS_API_TYPES_URLS_PATHS_MAP = {
  [SPECS_API_TYPE_MAIN]: SPECS_API_TYPE_MAIN,
  [SPECS_API_TYPE_LEVEL_ONE]: SPECS_API_TYPE_LEVEL_ONE,
  [SPECS_API_TYPE_TERMINAL_SERVICE]: SPECS_API_TYPE_TERMINAL_SERVICE,
  [SPECS_API_TYPE_ANDROID_SDK]: SPECS_API_TYPE_ANDROID_SDK,
  [SPECS_API_TYPE_IOS_SDK]: SPECS_API_TYPE_IOS_SDK,
  [SPECS_API_TYPE_WEB_SDK]: SPECS_API_TYPE_WEB_SDK,
  [SPECS_API_TYPE_PAX_SDK]: SPECS_API_TYPE_PAX_SDK,
};

export const SPECS_API_SWAGGER_NAMES_MAP = {
  [SPECS_API_TYPE_LEVEL_ONE]: "level1-api",
  [SPECS_API_TYPE_MAIN]: "main-api",
  [SPECS_API_TYPE_ANDROID_SDK]: "android-sdk",
  [SPECS_API_TYPE_IOS_SDK]: "ios-sdk",
  [SPECS_API_TYPE_WEB_SDK]: "web-sdk",
  [SPECS_API_TYPE_TERMINAL_SERVICE]: "terminal-api",
  [SPECS_API_TYPE_PAX_SDK]: "pax-sdk",
};

export const SPECS_API_TYPES_NAMES_MAP = {
  [SPECS_API_TYPE_MAIN]: "Main API",
  [SPECS_API_TYPE_LEVEL_ONE]: "Level One API",
  [SPECS_API_TYPE_TERMINAL_SERVICE]: "Terminal API",
  [SPECS_API_TYPE_ANDROID_SDK]: "Android SDK",
  [SPECS_API_TYPE_IOS_SDK]: "iOS SDK",
  [SPECS_API_TYPE_WEB_SDK]: "Web SDK",
  [SPECS_API_TYPE_PAX_SDK]: "PAX SDK",
};

export const SPECS_API_VERSION_V1 = "v1";
export const SPECS_API_VERSION_V2 = "v2";
export const SPECS_API_VERSION_V5 = "v5";
export const SPECS_API_VERSION_HIDDEN = "";
export const SPECS_API_VERSION_DROPDOWN_VISIBLE = "DROPDOWN_VISIBLE";

export const SPECS_API_VERSIONS = [
  SPECS_API_VERSION_V1,
  SPECS_API_VERSION_V2,
  SPECS_API_VERSION_V5,
];

export const SPECS_API_TYPES_VERSION_MAP = {
  [SPECS_API_TYPE_MAIN]: [SPECS_API_VERSION_V1, SPECS_API_VERSION_V2],
  [SPECS_API_TYPE_LEVEL_ONE]: [SPECS_API_VERSION_V1],
  [SPECS_API_TYPE_TERMINAL_SERVICE]: [
    SPECS_API_VERSION_V1,
    SPECS_API_VERSION_HIDDEN,
    SPECS_API_VERSION_DROPDOWN_VISIBLE,
  ],
  [SPECS_API_TYPE_ANDROID_SDK]: [
    SPECS_API_VERSION_V2,
    SPECS_API_VERSION_HIDDEN,
    SPECS_API_VERSION_DROPDOWN_VISIBLE,
  ],
  [SPECS_API_TYPE_IOS_SDK]: [
    SPECS_API_VERSION_V2,
    SPECS_API_VERSION_HIDDEN,
    SPECS_API_VERSION_DROPDOWN_VISIBLE,
  ],
  [SPECS_API_TYPE_WEB_SDK]: [
    SPECS_API_VERSION_V5,
    SPECS_API_VERSION_HIDDEN,
    SPECS_API_VERSION_DROPDOWN_VISIBLE,
  ],
  [SPECS_API_TYPE_PAX_SDK]: [
    SPECS_API_VERSION_V1,
    SPECS_API_VERSION_HIDDEN,
    SPECS_API_VERSION_DROPDOWN_VISIBLE,
  ],
};
export const SPECS_API_VERSION_DEFAULT = SPECS_API_VERSION_V2;

export default {
  ENV_PROD,
  ENV_DEV,
  ENV_LIST,
  SPECS_API_TYPE_MAIN,
  SPECS_API_TYPE_LEVEL_ONE,
  SPECS_API_TYPE_TERMINAL_SERVICE,
  SPECS_API_TYPE_ANDROID_SDK,
  SPECS_API_TYPE_IOS_SDK,
  SPECS_API_TYPE_WEB_SDK,
  SPECS_API_TYPE_PAX_SDK,
  SPECS_API_TYPES,
  SPECS_API_TYPES_URLS_PATHS_MAP,
  SPECS_API_TYPES_NAMES_MAP,
  SPECS_API_TYPES_VERSION_MAP,
  HTTP_LOCAL_PORT,
  HTTP_LOCAL_HOST,
  SPECS_API_VERSION_V1,
  SPECS_API_VERSION_V2,
  SPECS_API_VERSION_V5,
  SPECS_API_VERSIONS,
  SPECS_API_VERSION_DEFAULT,
  SPECS_API_VERSION_HIDDEN,
  SPECS_API_VERSION_DROPDOWN_VISIBLE,
};
